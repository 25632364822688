$line-width: 5em;
$circle-width: 8em;
$green: #8fcf8f;
$grey: #555;
$transitionSpeed: 200ms;
$card-bg: #f8f9fa;
$card-border: 1px solid #cdd4da;

.custom-switch-wrapper {
  background-color: $card-bg;
  border-radius: 8px;
  display: block;
  position: relative;
  padding: 1rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0px;

  &.disabled {
    opacity: 0.7;
  }

  .custom-switch .custom-control-label{
    &::before{
      width: 3rem;
      height: 1.5rem;
      border-radius: .8rem;
    }

    &::after{
      width: calc(1.5rem - 4px);
      height: calc(1.5rem - 4px);
      border-radius: .8rem;
    }
  }

  .custom-control-label{
    font-size: 1rem;
  }

  .custom-switch .custom-control-input:checked~.custom-control-label {
    &::after{
      transform: translateX(1.5rem);
    }
  }

  a{
    text-decoration: underline;
  }
}

div.kebab-menu {
  margin: 1rem;
  width: 1.125rem;
  height: 1.125rem;
  position: absolute;
  right: 0;
  text-align: center;

  .dot {
    width: .2rem;
    height: .2rem;
    margin: .2rem;
    border-radius: 50%;
  }

  .menu-content {
    z-index: 1000;
    padding: .5rem 0;
    white-space: nowrap;
    width: fit-content;
    position: absolute;
    right: -1rem;
    margin-top: .5rem;
    border-radius: .3rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);

    div {
      padding: .5rem 2rem;
    }
  }
}
