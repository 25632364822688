.navigation {
  .list-group {
    overflow: hidden;
  }

  .list-group-item {
    .actions {
      z-index: 0;
      width: 60px;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      position: absolute;
      right: 0;

      button {
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        border-radius: 50%;
        padding: 0;
      }

      svg {
        cursor: pointer;

        &.delete-icon {
          position: relative;
          top: 4px;
        }
      }
    }

    a {
      z-index: 1000;
      width: 100%;
      background: white;
      transition: width .5s;
    }

    &:hover {
      a {
        width: calc(100% - 60px);
      }
    }
  }

  .collapse-caret {
    transform: rotateZ(90deg);
    transition: transform linear .2s;
  }

  .collapse {
    ~ .card-header .collapse-caret {
      transition-delay: 0s;
    }

    &.show {
      ~ .card-header .collapse-caret {
        transform: rotateZ(-90deg);
      }
    }
  }

  .collapsing {
    ~ .card-header .collapse-caret {
      transition-delay: .1s;
    }
  }
}