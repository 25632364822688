@use "sass:math";
@import "icons.scss";
@import "radioButton.scss";
@import "navigation.scss";
@import url("https://fonts.googleapis.com/css?family=Nunito:200,300,400,600,700,800,900|Open+Sans:300,400,600,700,800&display=swap");

$card-border-radius: 1rem;
$base-margin: 2rem;
$border-default: 1px solid;

$orange: #ffbb33;
$green: #00c851;
$valid: #8ddd9e;
$red: #ff4444;

html,
body {
    max-width: 100%;
    overflow-x: hidden;
    position: relative;
}

.form-group {
    select,
    option {
        overflow-x: hidden;
        text-overflow: ellipsis;
    }
}

.App {
    text-align: center;
}

body {
    font-family: "Nunito", arial, sans-serif;
    min-height: 90vh;
}

.app-container {
    margin-top: $base-margin;
    margin-bottom: $base-margin;
}

@for $i from 1 through 10 {
    .w-em-#{$i} {
        width: #{$i * 1em};
    }
}

@for $i from 1 through 100 {
    .w-percent-#{$i} {
        width: #{$i * 1%};
    }
}

@for $i from 1 through 40 {
    .text-#{$i * 5} {
        font-size: #{$i * 0.05em} !important;
    }
}

@for $i from 1 through 100 {
    .flex-basis-#{$i} {
        flex-basis: #{$i * 1%};
    }
}

@for $i from 1 through 200 {
    .h-px-#{$i} {
        height: #{$i * 1px};
    }
}

.prewrap {
    white-space: pre-wrap;
}

.break-row {
    flex-basis: 100%;
}

.h7 {
    font-size: 1.125rem;
}

.markdown-p-margin-reset {
    & > p {
        margin-bottom: 0;
    }
}

fieldset.bordered-fieldset {
    border-radius: 4px;
    padding: 1rem;
    margin: 1rem 0;

    legend {
        width: auto;
        padding: 0 0.5rem;
        font-size: 1.2rem;
        //font-weight: bold;
    }
}

div.scrollable-div {
    max-height: 350px;
    width: 100%;
    padding: 0.5rem 0.5rem 2rem 0.5rem;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

textarea[disabled] {
    resize: none;
}

.additional-info {
    font-size: small;
    text-align: right;
    a {
        color: $orange;
        font-weight: bold;
        &:hover,
        &:active,
        &:visited {
            text-decoration: none;
        }
    }
}

.rounded-card {
    border-radius: $card-border-radius !important;
    margin: 0.5rem 0;
    &.card > .list-group:last-child {
        border-bottom-right-radius: calc(#{$card-border-radius} - 1px);
        border-bottom-left-radius: calc(#{$card-border-radius} - 1px);
    }
    &.card > .list-group:first-child {
        border-top-left-radius: calc(#{$card-border-radius} - 1px);
        border-top-right-radius: calc(#{$card-border-radius} - 1px);
    }
}

.info-card {
    *:first-child {
        width: 2rem;
        height: 2rem;
        line-height: 2rem;
        border: 2px solid white;
        border-radius: 50%;
    }
    *:last-child {
        width: 90%;
        font-size: 0.8rem !important;
    }
}

.form-control-file {
    overflow: hidden !important;
}

.green-input-group {
    span {
        input.hidden-checkbox {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
        & + .styled-checkbox {
            position: relative;
            cursor: pointer;
            padding: 0;
        }

        & + .styled-checkbox:before {
            content: "";
            margin-right: 10px;
            display: inline-block;
            vertical-align: text-top;
            width: 20px;
            height: 20px;
            background: white;
        }
    }
}

.status {
    &.pending {
        color: $orange;
    }
    &.accepted {
        color: $green;
    }
    &.rejected {
        color: $red;
    }
}

.spinner {
    width: 100%;
    display: flex;
    justify-content: center;
}

.card {
    margin-bottom: 1rem;
    border-radius: 0.625rem;

    & .checklist-title {
        padding: 0.5rem;
        margin-left: -1rem;
        margin-right: -1rem;
        border-radius: 0.25rem;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.1);

        h4 {
            margin-bottom: 0;
        }
    }

    & .checklist-content {
        margin: 0;
        border: none;

        .card-subtitle {
            font-weight: 600;
            margin: 0.5rem 0 1rem;
        }

        .card-text {
            border: none;
            padding-bottom: 0;
        }
    }

    .card-header {
        font-size: 1.125rem;
        font-weight: 600;
        background-color: transparent;
        border: none;
        padding: 1rem 1rem 0;
        text-align: center;

        .header-info-icon {
            position: absolute;
            right: 1em;
            cursor: pointer;
        }
    }

    .card-title {
        font-size: 1.125rem;
    }

    .card-text {
        padding-bottom: 1rem;
    }

    .card-body {
        padding: 1rem;

        h5 {
            margin-bottom: 1rem;
        }

        .trip-summary {
            margin-bottom: 1rem;
            padding-bottom: 1rem;
            font-size: 1.125rem;

            .row {
                &:not(:last-child) {
                    margin-bottom: 1rem;
                }
                &:first-child {
                    font-size: 1.25rem;
                    font-weight: 500;
                }
            }
        }
        .passenger-list {
            border-radius: 0;

            .passenger-link {
                padding: 0;
                padding-bottom: 1rem;
                margin-bottom: 1rem;
                border-radius: 0;

                a {
                    color: inherit;

                    .passenger-name {
                        font-size: 1.125rem;
                        font-weight: 600;
                        width: 65%;
                    }

                    svg {
                        position: absolute;
                        right: 0;
                        top: 0.5rem;
                    }
                }

                .manage-checklist {
                    position: absolute;
                    right: 2rem;
                    top: 0;
                    width: 5rem;
                    font-size: 0.8rem;
                    text-align: right;
                    &.complete {
                        color: $valid !important;
                    }
                }
            }
        }

        legend {
            font-size: 1.125rem;
            text-align: center;
        }

        .form-group {
            padding-bottom: 1rem;

            &.no-border {
                border-bottom: none;
                padding-bottom: 0;
            }

            .form-control {
                height: 2.75rem;
                border-radius: 0.5rem;
                text-overflow: ellipsis;
            }

            select {
                appearance: none;
            }

            .input-group-btn {
                position: absolute;
                right: 0.5rem;
                top: 1rem;
                z-index: 100;
            }
        }

        button,
        .button {
            font-size: 1.125rem;
            font-weight: 600;
            border: none;
            border-radius: 0.5rem;
            &:not(.icon) {
                min-height: 2.75rem;
            }
        }
    }
}

button.submit-button {
    margin: math.div($base-margin, 2) $base-margin;
    border-radius: 0.5rem;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 2rem;
}

.btn.btn-toggle svg {
    transform: translateY(-0.1rem);
    margin-right: 0.25rem;
}

.trip-menu {
    float: right;

    .trip-menu-popup {
        position: absolute;
        right: 0;
        top: 2.75rem;
        z-index: 1000;

        font-size: 1rem;
        font-weight: 400;
        text-align: left;
        white-space: nowrap;

        &:after {
            content: "";
            position: absolute;
            top: -77px;
            right: -16px;
            width: 100vw;
            height: 100vh;
            background-color: #555555;
            opacity: 0.3;
            z-index: -1;
        }
    }
}

.form-actions {
    .card {
        background-color: transparent;
        border: none;
    }
}

.credential-box {
    position: relative;
    min-height: 6rem;
    display: flex;
    padding: 0.5rem 0;

    .credential-step {
        padding: 0 1rem;
        font-size: 4.125rem;
        font-weight: 600;
        text-align: center;
    }

    .credential-box-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 0.5rem;
    }

    .credential-title {
        width: 80%;
    }
}

div.birth-date {
    .form-control {
        &:valid,
        &:invalid {
            padding-right: 0;
        }
    }
}

.image-frame {
    max-height: 16rem;
    max-width: 90%;
}

.rbt.has-aux {
    .form-control {
        padding-right: 34px;
    }
    .rbt-aux {
        align-items: center;
        display: flex;
        bottom: 0;
        justify-content: center;
        position: absolute;
        right: 0;
        top: 0;
        width: 34px;

        .rbt-close {
            opacity: 1;
            margin-left: 0.2em;
        }
    }
}

//maps
.w-45 {
    width: 45%;
}

.height-auto {
    height: auto !important;
}

.border-bottom-default {
    border-bottom: $border-default;
}

.rounded {
    border-radius: 0.25rem !important;
}

.h-fit-content {
    height: fit-content !important;
}

div:focus {
    outline: none;
}

.search {
    :focus {
        outline: none;
    }
    input:focus {
        outline-offset: 0;
    }
}

.map-wrapper {
    border-radius: 10px;
    overflow: hidden;
}

.test-details-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    min-height: 100%;
    width: 100%;
    opacity: 1;
}

.test-details-header {
    font-size: 1.8rem;
}

.test-details-lead {
    font-size: 1.125rem;
}

[data-reach-combobox-popover] {
    border-radius: 0.25rem;
    margin-top: 0.125rem;
}

.uppercase {
    text-transform: uppercase;
}

.info-modal {
    margin: 0 2rem !important;
    .modal-content {
        border-radius: 1rem;
        .modal-body {
            padding: 2rem;
            header,
            section,
            summary {
                margin-bottom: 2rem;
            }
            header {
                text-align: center;
            }
            summary {
                text-align: center;
                display: block;
                cursor: default;
            }
        }
    }
}

//Bootstrap modal padding fix
body.modal-open {
    padding-right: 0 !important;
}

.card-review-status-style {
    min-height: calc(100vh - 5rem);
}

.card-body-review-status-style {
    display: flex;
    flex-direction: column;
}

.card-flex-grow-element {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.travel-auth-document-status {
    display: flex;
    align-items: center;
    flex-flow: row;
    padding: 0.5rem;
}
.sub-step-option {
    width: 100%;
    padding: 1rem 0;
    display: grid;
    grid-template-columns: 2em auto 2em;
    grid-template-rows: min-content min-content;
    .sub-step-option-title {
        grid-column-start: 2;
    }
    .sub-step-option-text {
        grid-column-start: 2;
    }
    .chevron-right {
        grid-column-start: 3;
        grid-row: 1 / 3;
        justify-self: center;
        align-self: center;
    }
}

.border-modal div {
    border-radius: 30px;
}

.border-2 {
    border-width: 2px !important;
    border-radius: 5px;
}

ol {
    padding-inline-start: 1rem;
    li {
        padding-top: 0.5rem;
    }
}

.alert a {
    color: #526b8b;
}

.premier-options > div:first-child {
    margin-left: -12px;
}
.premier-options > div:last-child {
    margin-right: -12px;
}

.manage-interlie-trip {
    .interline-block {
        border-radius: 7px;
    }

    .interline-block.incomplete,
    .interline-block.unavailable {
        border: 1px solid $orange;
    }

    .interline-block.complete {
        border: 1px solid $green;
    }

    .interline-block.unsupported {
        border: 1px solid rgba(255, 255, 255, 0.3);
    }

    .interline-flight-block {
        .interline-flight-block-view-trevel {
            font-size: 15px;
            min-height: 1.75rem !important;
            width: 100%;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 7px;
            border-bottom-right-radius: 7px;
        }

        .flight-block-status {
            padding-right: 10px;
            border-radius: 5px;
            font-size: 13px;
            padding-left: 10px;
        }

        .flight-block-status.incomplete,
        .flight-block-status.unavailable {
            border: 1px solid $orange;
            color: $orange;
        }

        .flight-block-status.complete {
            border: 1px solid $green;
            color: $green;
        }

        .interline-flight-block-icon {
            display: flex;
            padding: 15px;
            margin: auto;
            justify-content: center;
        }

        .interline-flight-block-airport {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }

        .interline-flight-block-container {
            display: flex;
            flex-direction: row;
        }

        .interline-flight-block-airport-right {
            width: 40%;
            text-align: start;
        }

        .interline-flight-block-airport-left {
            width: 40%;
            text-align: end;
        }

        .interline-flight-block-arrow {
            width: 20%;
        }

        .interline-flight-segment-switch {
            border: none;
            border-radius: 0;
        }

        .interline-flight-block-info-top-icon {
            margin: auto 1px;
        }
    }
}

.flight-info {
    .interline-flight-block-airport-right {
        width: 45%;
        text-align: start;
    }

    .interline-flight-block-airport-left {
        width: 45%;
        text-align: end;
    }

    .interline-flight-block-arrow {
        width: 10%;
    }
}

.was-validated :invalid ~ .test-note {
    display: none !important;
}

.visa-status {
    padding: 0 10px;
    border-radius: 5px;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.visa-status.incomplete {
    border: 1px solid $orange;
    color: $orange;
}

.visa-status.complete {
    border: 1px solid $green;
    color: $green;
}

.no_click_action {
    pointer-events: none;
}

.visa-scan-block {
    padding: 10px;
    border-radius: 10px;
    margin: 0 auto 10px;
    width: 160px;
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .visa-svg-box {
        flex: 2;

        svg {
            width: 80px;
            height: 80px;
        }
    }

    .visa-text-box {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.visa-scan-block-disabled {
    opacity: 0.65;
    pointer-events: none;
}

.layout-with-at {
    min-height: calc(100vh - 4rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;
}

.at-container {
    width: 100vw;
    height: calc(100vw / 3.9);

    .at-img-container {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .at-img {
        width: 100%;
        object-fit: cover;
    }
}

@media (min-width: 500px) {
    .at-container {
        width: 500px;
        height: calc(500px / 3.9);

        .at-img-container {
            left: initial;
            right: initial;
        }

        .at-img {
            width: 500px;
        }
    }
}

.feedback-modal-circle {
    padding: 8px;
    border: $border-default;
    border-radius: 50%;
}

.feedback-modal-circle-fill {
    background: $orange;
}

.guideModal {
    h2 {
        font-size: 18px;
    }
    h3 {
        font-size: 14px;
    }
    p,
    li {
        font-size: 13px;
        text-align: justify;
    }
}

.hr_instructions {
    text-align: start;

    header {
        font-weight: bolder;
        text-align: center;
    }

    summary {
        text-align: center;
        list-style-type: none;
    }
}

.hr_note {
    text-align: center;
    font-size: 20px;
    font-weight: bolder;

    & > p {
        margin-bottom: 0;
    }
}

.hr_substep {
    min-height: 140px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    &__icon {
        flex: 2;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 8;

        h2 {
            font-size: 18px;
            text-align: start;
            font-weight: bold;
        }

        p {
            font-size: 15px;
            text-align: start;
            margin-bottom: 0;
        }
    }

    &__chevron {
        flex: 1;
    }
}

.card_wrapper {
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
    }

    .header__title {
        flex: 6;
        margin-left: 24px;
    }

    .header__title--iconless {
        margin-left: 0;
    }

    .header__icon {
        cursor: pointer;
    }
}

.status_box {
    width: fit-content;
    min-width: 90px;
    margin: 0 auto;
}

.input_container {
    position: relative;

    input.input--hidden {
        position: absolute;
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
    }

    h5 {
        text-align: center;
    }
}

.document_option {
    min-height: 100px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    &__icon {
        flex: 2;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 8;

        h2 {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 0;
        }
    }

    &__chevron {
        flex: 1;
    }
}

.invisible--no-size {
    visibility: hidden;
    width: 0 !important;
    height: 0 !important;
    padding: 0 !important;
    border: none !important;
    margin: 0 !important;
}

.document_validation {
    position: relative;
    min-height: 8rem;
    display: flex;
    padding: 0.5rem 0;

    &__step {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 0.5rem;
        font-size: 4rem;
        font-weight: 600;
    }

    &__content {
        flex: 9;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 0.5rem;

        .content__title {
            width: 100%;
            text-align: start;
        }
    }

    &__status {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.flight_details_legend {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .legend_title {
        font-size: 1.125rem;
        flex: 9;
        margin-bottom: 0;
    }

    .legend_title--offset {
        padding-left: 1rem;
    }

    .legend_icon_container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 0.5rem;
        cursor: pointer;
    }
}

.info_icon_container {
    cursor: pointer;
}

.image_information_preview {
    display: flex;
    align-items: center;
    gap: 20px;
}

.e_visa_validation_complete_info {
    div {
        display: flex;
        justify-content: space-between;
        margin: 0 5vw;
    }
}
